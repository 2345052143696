<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="120.8" x2="83.19" y1="135.35" y2="50.16">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <rect :fill="background" height="183" width="183"/>
     <path d="M122.07,104c0-.84-.09-1.67-.17-2.5a43.8,43.8,0,0,0-2.82-11.87,30.3,30.3,0,0,1-13,2.86c-.6,0-1,0-1.11,0a6.49,6.49,0,0,1-4.8-2.42,5.74,5.74,0,0,1-1-4.72,21,21,0,0,1,1.13-3.11c.31-.75.93-2.27,1.4-3.56a28.58,28.58,0,0,1-7.76-14.22c-.72-.29-1.45-.55-2.19-.8s-1.66-.52-2.5-.74a44.41,44.41,0,1,0,32.91,42.9C122.11,105.22,122.1,104.62,122.07,104Zm-54-20.77a3,3,0,0,1,3.42-1.87l.18.13,6,2.09,6-2.09a3,3,0,0,1,3.55,1.6l0,.14,1.92,5.07,2.5,6.59,2.16,5.7H61.53Zm23.43,46.19a10,10,0,0,1-10.85-9.15,5.24,5.24,0,0,0-6,0,10,10,0,0,1-10,9.24,10.06,10.06,0,1,1,9.81-12.35,8,8,0,0,1,6.31,0,10.05,10.05,0,1,1,10.67,12.26Zm12.06-22.88H51.8v-3h51.78Zm-5.81,13a7.2,7.2,0,1,1-7.28-7.28h.17A7.18,7.18,0,0,1,97.77,119.54Zm-25.93,0a7.11,7.11,0,1,1-7.11-7.28A7.21,7.21,0,0,1,71.84,119.54ZM131,32.77c-18,0-32.74,11.72-32.74,26.2a23.69,23.69,0,0,0,9,18c0,2-3,8.5-3.25,9.48s1.2,1,1.2,1,6.49.38,11.79-2.39a13.8,13.8,0,0,0,2.44-1.59A41.77,41.77,0,0,0,131,85.16c18,0,32.74-11.72,32.74-26.19S149,32.77,131,32.77Zm0,45.84c-14.47,0-26.2-8.81-26.2-19.64S116.52,39.32,131,39.32s26.2,8.81,26.2,19.65S145.46,78.61,131,78.61Z" id="icon-anoniemaanvraag" style="fill:url(#Naamloos_verloop_4);"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>